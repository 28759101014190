
// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Cabin|PT+Sans:700);

// vars
$blue: #009DDC;
$green: #0CCA4A;
$orange: #F05D23;
$pink: #F51AA4;
$purple: #7E1F86;
$red: #FF0035;
$coral: #EF6F6C;
$dark-blue: #253D5B;
$grey: #615D6C;

$mid-grey: #dedede;
$light-grey: #eaeaea;
$vlight-grey: #f7f7f7;

$text-dark: #414141;
$warning: #840101;
$valid: #0CCA4A;

$colors: $blue, $green, $orange, $pink, $purple, $red, $coral, $dark-blue, $grey;
$colorKeys: "blue", "green", "orange", "pink", "purple", "red", "coral", "dark-blue", "grey";

$corner-radius: 14px;
$corner-radius-sml: 4px;

// Partials
@import "scss/_mixins.scss";
@import "scss/_ui.scss";
@import "scss/_form.scss";
@import "scss/_index.scss";
@import "scss/_login.scss";
@import "scss/_settings.scss";
@import "scss/_chat.scss";
