
/* ui */

a,button {
  transition: color 0.3s ease-out, border-color 0.3s ease-out, background-color 0.3s ease-out;
}

.btn {
  margin: auto 10px;
  background-color: $blue;
  color: white;
  border: 1px solid $blue;
  border-radius: 10px;
  padding: 8px 13px;
  font-weight: 600;
  font-size: 1rem;
  -webkit-appearance:none;
  &:hover, &:active, &:focus {
    cursor: pointer;
    background-color: darken($blue ,10%);
  }
  &:disabled {
    background-color: $light-grey !important;
    color: $grey !important;
    border-color: $light-grey !important;
    &:hover, &:active, &:focus {
      cursor: default !important;
      background-color: $light-grey !important;
    }
  }
}

.btn-games {
  width: 60px;
  margin: 0 10px 0 0;
  background-color: transparent;
  color: $blue;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 2rem;
  -webkit-appearance:none;
  cursor: pointer;
  &:hover, &:active, &:focus {
    color: darken($blue ,10%);
  }
}

.btn-share {
  margin: 0 10px 0 0;
  label {
    width: 60px;
    font-size: 2rem;
    color: $blue;
    transition: 0.3s ease-out;
    cursor: pointer;
    &:hover, &:active, &:focus {
      color: darken($blue ,10%);
    }
  }
  input {
    display: none;
  }
  &--disabled {
    label {
      color: $grey !important;
    }
    &:hover, &:active, &:focus {
      label {
        cursor: default !important;
        color: $grey !important;
      }
    }
  }
}

.btn-send {
  width: 40px;
  height: 40px;
  text-align: center;
  padding:0;
  border-radius: 40px;
  font-size:1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin:0;
    line-height: 1;
    transform: translate(2px,-1px);
  }
}

.btn-close {
  position:absolute;
  top:0;
  right:0;
  background:#fff;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  text-align: center;
  padding: 1px 0;
  border: 1px solid $grey;
  color: $grey;
  cursor: pointer;
  i:before {
    margin:0;
    line-height: 1;
  }
  &:hover, &:active, &:focus {
    background:$grey;
    color: #fff;
  }
}

.btn-save-username {
  padding: 0 10px;
}

.plusminus {
	position:absolute;
	width: 15px;
	height: 15px;
	top:20px;
	right:15px;
	&:before,
	&:after { content: ""; position: absolute; background-color: $grey; transition: transform 0.25s ease-out; }
	&:before{ top: 0; left: 50%; width: 2px; height: 100%; margin-left: -1px; }
	&:after{ top: 50%; left: 0; width: 100%; height: 2px; margin-top: -1px; }
}

.expander {
  .expander-head {
    position:relative;
    cursor: pointer;
  }
  .expander-content {
    display: none;
  }
  &--expanded {
    .plusminus {
			&:before { transform: rotate(90deg); }
			&:after{ transform: rotate(180deg); }
		}
  }
}


/* burger button */
.lines-button {
	display: block;
	width: 58px;
	height: 58px;
	margin: 0;
	padding: 17px;
	background: #fff;
	text-shadow: none !important;
	border: none !important;
	box-shadow: none !important;
	transition: none;
	cursor: pointer;
	user-select: none;

	span {
		display: block;
		margin-bottom: 3px;
	}
	&.nav-close .lines {
		background: transparent;
		&:before, &:after {
			-ms-transform-origin: 50% 50%;
			-moz-transform-origin: 50% 50%;
			-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
			top: 0;
      width: 25px;
      left: 0;
		}
		&:before {
			-ms-transform: rotate3d(0, 0, 1, 45deg);
			-webkit-transform: rotate3d(0, 0, 1, 45deg);
			-moz-transform: rotate3d(0, 0, 1, 45deg);
			transform: rotate3d(0, 0, 1, 45deg);
		}
		&:after {
			-ms-transform: rotate3d(0, 0, 1, -45deg);
			-webkit-transform: rotate3d(0, 0, 1, -45deg);
			-moz-transform: rotate3d(0, 0, 1, -45deg);
			transform: rotate3d(0, 0, 1, -45deg);
		}
	}

	.lines {
		display: inline-block;
		width: 22px;
		height: 3px;
		background: $grey;
		transition: 0.3s;
		position: relative;
		&:before, &:after {
			display: inline-block;
			width: 22px;
			height: 3px;
			background: $grey;
			transition: 0.3s;
			position: absolute;
			left: 0;
			content: '';
		}
		&:before {
			top: 8px;
		}
		&:after {
			top: -8px;
		}
	}
  &.active {
    background:#fff;
    // border-radius:50%;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
    // .lines {
  	// 	&:before, &:after {
  	// 		background: #fff;
    //   }
    // }
  }
}

.error-msg {
  font-size: 0.9rem;
  color: $warning;
}


.username-select {
  .inner {
    display: flex;
    .input-wrap {
      position: relative;
      border: 1px solid rgb(224, 224, 224);
      border-right:none;
      border-radius: 10px;
      height: 33px;
      overflow: hidden;
      flex-grow: 1;
      input {
        width: 100%;
        height: 20px;
        padding: 5px 30px 5px 10px;
        font-size: 1.2rem;
        height: 31px;
        border:none;
        box-shadow: none;
        position: relative;
      }

      &:focus-within {
        border: 1px solid #66a6ff;
        border-right:none;
        outline: none;
      }
    }
  }

  .error-msg {
    display: block;
    margin-top: 5px;
    font-size: 0.75rem;
  }

  &--valid {
    .inner {
      .input-wrap {
        &:after {
          content: "\e80c";
          font-family: fontello;
          position: absolute;
          right: 4px;
          top: 7px;
          color: $valid;
          font-size: 1.2rem;
        }
      }
    }
  }
  &--error {
    .inner {
      .input-wrap {
        &:after {
          content: "\e806";
          font-family: fontello;
          position: absolute;
          right: 4px;
          top: 6px;
          color: $warning;
          font-size: 1.2rem;
        }
      }
    }
  }

  &.hasbtn {
    .input-wrap {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right:0;
    }
    .btn {
      margin: 0;
      height: 33px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}


/* loader */


.spinner {
  width: 56px;
  height: 56px;
  position: absolute;
}

.cube1, .cube2 {
  background-color: $grey;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}



.sk-circle {
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
