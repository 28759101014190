html, body {
  margin:0;
  height: 100%;
  background: $light-grey;
  color: $text-dark;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*:focus {
	outline:none;
}

body {
  margin: 0;
  font-family: 'Cabin', Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  font-family: 'PT Sans', Sans-Serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hidden {
	display: none;
}
.screen-reader-text,
.visually-hidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	height: 1px;
	width: 1px;
	word-wrap: normal;
}
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
	position: static !important;
	clip: auto;
	overflow: visible;
	height: auto;
	width: auto;
}
.invisible {
	visibility: hidden;
}
