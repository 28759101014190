.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../images/bg.png) no-repeat center;
  background-size: cover;
  padding:10%;
  &__modal {
    background: #fff;
    width: 100%;
    max-width:400px;
    border-radius: 10px;
    padding:50px 30px;
    -webkit-box-shadow: 0px 0px 34px 6px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 34px 6px rgba(0,0,0,0.25);
    text-align: left;
    .inner {
      h1 {
        text-align: left;
        font-size: 1.8rem;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 40px;
        font-size: 1rem;
      }
    }
    .form-row {
      margin-bottom: 20px;
      input {
        width: 100%;
        border-radius: 0;
        padding: 10px 10px;
        font-size: 1.2rem;
        border:none;
        border-bottom: 1px solid rgb(224, 224, 224);
      }
      .btn-wrap {
        margin: 0 -10px;
        text-align: right;
        .btn {
          padding:10px 20px;
          border-radius: 20px;
          font-size: 1rem;
        }
        .btn-start {
          background: $orange;
          border-color: $orange;
          &:hover, &:active, &:focus {
            background: darken($orange ,10%);
          }
        }
      }
    }
  }

  .error-msg {
    display: block;
    margin-top: 40px;

    &--username {
      text-align: right;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}
