

.settings {
  width: 250px;
  border-left: 1px solid #e0e0e0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position:fixed;
  top:0;
  right:-250px;
  bottom:0;
  transition: transform 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  transform: translate(0, 0);
  
  .circle-picker  {
    width: 100% !important
  }

  &__inner {
    overflow: auto;
    padding: 0 7%
  }
  &__header {
    padding:20px 0 25px 0;
    color: $grey;
    font-size: 1.2rem;
    span {
      font-weight: bold;
    }
  }
  &__lbl {
    width: 100%;
    padding: 10px 0 5px 0;
    font-size: 1rem;
  }
  &__input {
    width: 100%;
    padding: 5px 10px 10px 5px;

  }
  .btn-wrap {
    margin-top:50px;
    padding:0 10px;
    .btn {
      width: 100%;
      margin:0;
    }
  }

  .lines-button {
    position:absolute;
    right:100%;
    top: 14px;
  }


  @include respond-medium-down {
    width: calc(100vw - 100px);
    right: calc((100vw - 100px) * -1);
  }
}

.clickTrap {
  background: rgba(0,0,0,0.6);
  position:fixed;
  top:0;
  left:0;
  right:100%;
  bottom:0;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.settings-out {
  .settings {
    transform: translate(-250px, 0);
  }
  .clickTrap {
    right:0;
    opacity: 1;
  }
  @include respond-medium-down {
    .settings {
      transform: translate(-100%, 0);
    }
  }
}
