
/* chat app */

.loading-chat {
  width: 100vw;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-app {
  width: 100%;
  height: 100%;
  display: flex;‍

  .channel-list {

  }

  .no-content-message {
    color: #cccccc;
    font-style: italic;
    font-size: 1rem;
    text-align: center;
    margin: 20px 10px;
  }

  .channel-panel {
    background:#fff;
    width: 280px;
    margin: 0;
    margin-right: 0;
    border-right: none;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__topbar {
      display: flex;
      align-items: center;
      min-height: 80px;
      padding:20px 4% 10px 4%;
      h1 {
        margin:0;
        display: inline-block;
        padding-bottom: 10px;
        line-height: 1;
      }
      .logo {
        width: 42px;
        margin-right: 10px;
        display: block
      }
    }
    &__inner {
      overflow: auto;
      flex-grow: 1;
      padding:0 4%;
    }

    &__header {
      padding:20px 10px 10px 10px;
      color: $grey;
      font-size: 1.2rem;
      span {
        font-weight: bold;
      }
    }

    &__add {
      .btn-add-channel {
        display: block;
        margin:10px 0;
        background: $blue;
        color:#fff;
        padding: 10px 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        transition: color 0.3s ease-out, border-color 0.3s ease-out, background-color 0.3s ease-out;
        cursor: pointer;
        font-weight: 500;
        &:hover, &:active, &:focus {
          background-color: darken($blue ,10%);
        }
      }
    }

    .expander {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 5px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 200px;
        height: 1px;
        background: $light-grey;
        bottom:0;
        left:50%;
        margin-left: -100px;
      }
    }
  }

  .channel-list {
    flex-grow: 1;
    margin-bottom: 10px;
  }

  .channel-item {
    margin: 4px 0;
    padding: 10px;
    border-radius: 10px;
    background: $vlight-grey;

    &__name {
      margin-bottom: 10px;
      font-weight: bold;
      text-transform: capitalize;
    }

    &__meta {
      font-size: 0.75rem;
      span {
        display: inline-block;
        margin-right: 15px;
        &.channel-author {
          display: block;
          margin-top: -6px;
          margin-bottom: 10px;
        }
        i {
          display: inline-block;
          margin-right: 5px;
          font-size: 1rem;
          &.icon-chatbot {
            font-size: 0.9rem;
            margin-right: 8px;
          }
        }
      }
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.08);
    }

    &:hover, &:active, &:focus {
      background-color: rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
  }

  .user-item {
    margin: 4px 0;
    padding: 10px;
    border-radius: 10px;
    background: $vlight-grey;
    position:relative;

    &.active {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:hover, &:active, &:focus {
      background-color: rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
    &--self {
      &:hover, &:active, &:focus {
        background-color: $vlight-grey;
        cursor: default;
      }
    }
    &__meta {
      margin-top: 4px;
    }
    .badge-num-messages {
      padding: 2px 4px;
      background: $blue;
      color: #fff;
      text-align: center;
      font-size: 0.75rem;
      min-width: 18px;
      border-radius: 9px;
      display: inline-block;
      position: absolute;
      top: -1px;
      right: -4px;
    }
  }

  .messages-panel {
    border-left: 1px solid rgb(224, 224, 224);
    background: #fff;
    margin: 0;
    height: 100%;
    flex-grow: 1;
    width: calc(100% - 280px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .error-msg {
      font-size: 0.6rem;
      text-align: center;
      display: block;
      margin: 5px 10px 0 0;
    }

    .message-topbar {
      min-height: 80px;
      width: 100%;
      border-bottom: 1px solid rgb(224, 224, 224);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:15px 60px 15px 20px;
      .channel-name {
        font-size: 2rem;
        flex-grow: 1;
        &__header {
          display:block;
        }
        .user-status {
          display:block;
          margin-top: 5px;
        }
      }
      .no-content-message {
        text-align: left;
      }

      .btn-clearChannel {
        display:none;
        width: 40px;
        margin: 0 10px 0 -10px;
        background-color: transparent;
        color: $blue;
        border: none;
        border-radius: 0;
        padding: 0;
        font-size: 1.5rem;
        -webkit-appearance:none;
        &:hover, &:active, &:focus {
          color: darken($blue ,10%);
        }
      }
    }

    .chatroom-terms {
      width: 100%;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding:15px 20px;
      color: $grey;
    }

    .message-list {
      align-self: stretch;
      flex-grow: 1;
      overflow: auto;
      display:flex;
      flex-direction:column-reverse;
      &__inner {
        padding: 40px 15px;
        width: 100%;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .message-attachments {
      width: 100%;
      padding: 0 15px 10px 15px;
      display: flex;
      .attachment {
        width:60px;
        height:60px;
        padding:4px;
        position:relative;
        .inner {
          background-color: $grey;
          padding:2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          position:absolute;
          top:4px;
          left:4px;
          right:4px;
          bottom:4px;
          border-radius: 3px;
        }
        .image {
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
        .upload-failed {
          color: #fff;
          font-size: 1.5rem;
        }
        &--image {
          img {
            width: 100%;
            height:100%;
          }
        }
      }
    }

    .message-input {
      width: 100%;
      border-top: 1px solid rgb(224, 224, 224);
      background-color: $vlight-grey;
      display: flex;
      padding:10px 0;
      align-items: center;

      .col-message {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .col-actions {

      }

      &__msg {
        flex-grow: 1;
        height:100%;
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 20px;
        border: 1px solid rgb(224, 224, 224);
        background: #fff;
        overflow: hidden;
        &:focus-within {
          border: 1px solid #66a6ff;
          outline: none;
         }
        textarea,
        input {
          margin: auto;
          width: 100%;
          border:none;
          box-shadow: none;
          padding: 5px 10px;
          resize: none;
          flex-grow: 1;
          font-size: 1.125rem;
        }

        .msg-wrap {
          width: 100%;
        }
      }

      &__btns {
        display: flex;
        flex-direction: column;
        button {
          margin-bottom: 5px;
        }
      }
    }

    .message-item {
      text-align: left;
      padding: 10px 0 0 0;
      display: flex;
      flex-direction: column;
      &__attachment .inner,
      &__content .inner {
        background: $light-grey;
        border: 1px solid $light-grey;
        padding: 10px 15px;
        border-radius: $corner-radius;
        color: #333;
        font-weight: 500;
        display: block;
        float:left;
        span {
          display: inline-block;
        }
      }
      &__meta {
        text-align: left;
        padding: 0 10px;
        font-size: 0.65rem;
      }
      &__date {
        color: #868585;
        padding:5px 0 5px 0;
      }
      &__sender {
        padding: 0 0 5px 0;
      }
      &__status {
        color: $grey;
        display: none;
        padding-left: 10px;
        padding-top: 5px;
        .status-tick {
          opacity: 0.1;
          margin: 0 -2px;
          i {
            font-size: 0.75rem;
            margin:0;
            &:before {
              margin:0;
            }
          }
        }
        &.status-sent {
          .status-tick--sent {
            opacity: 0.7;
          }
        }
        &.status-read {
          .status-tick--read {
            opacity: 0.7;
          }
        }
      }
      &__attachment {
        margin-bottom:4px;
        .inner {
          display: inline-block;
          width: 100%;
          max-width: 200px;
          padding:4px;
          border-radius: $corner-radius-sml;
        }
        img {
          width:100%;
          height:auto;
          display: block;
          overflow: hidden;
          border-radius: $corner-radius-sml;
        }
      }
      &.is-empty {
        div:nth-last-child(2) {
          margin-bottom:0;
        }
      }
      &--start {
        .message-item__meta {
          // display: none;
        }
        .message-item__content .inner {
          border-bottom-left-radius: $corner-radius-sml;
          img {
            border-bottom-left-radius: $corner-radius-sml;
          }
        }
      }
      &--mid {
        padding-top: 4px;
        .message-item__meta {
          // display: none;
        }
        .message-item__content .inner {
          border-top-left-radius: $corner-radius-sml;
          border-bottom-left-radius: $corner-radius-sml;
          img {
            border-top-left-radius: $corner-radius-sml;
            border-bottom-left-radius: $corner-radius-sml;
          }
        }
      }
      &--end {
        padding-top: 4px;
        .message-item__content .inner {
          border-top-left-radius: $corner-radius-sml;
          img {
            border-top-left-radius: $corner-radius-sml;
          }
        }
      }

      &--single {

      }
      &--me {
        text-align: right;
        .message-item__attachment .inner,
        .message-item__content .inner {
          background: $blue;
          border: 1px solid $blue;
          color:#fff;
          float:right;
        }
        .message-item__meta {
          text-align: right;
        }
        .message-item__status {
          display: inline-block;
          color: #fff;
          .status-tick {
            opacity: 0.2
          }
          &.status-sent {
            .status-tick--sent {
              opacity: 0.8;
            }
          }
          &.status-read {
            .status-tick--read {
              opacity: 0.8;
            }
          }
        }

        &.message-item--start {
          .message-item__content .inner {
            border-bottom-left-radius: $corner-radius;
            border-bottom-right-radius: $corner-radius-sml;
            img {
              border-bottom-left-radius: $corner-radius;
              border-bottom-right-radius: $corner-radius-sml;
            }
          }
        }
        &.message-item--mid {
          .message-item__content .inner {
            border-bottom-left-radius: $corner-radius;
            border-bottom-right-radius: $corner-radius-sml;
            border-top-left-radius: $corner-radius;
            border-top-right-radius: $corner-radius-sml;
            img {
              border-bottom-left-radius: $corner-radius;
              border-bottom-right-radius: $corner-radius-sml;
              border-top-left-radius: $corner-radius;
              border-top-right-radius: $corner-radius-sml;
            }
          }
        }
        &.message-item--end {
          .message-item__content .inner {
            border-top-left-radius: $corner-radius;
            border-top-right-radius: $corner-radius-sml;
            img {
              border-top-left-radius: $corner-radius;
              border-top-right-radius: $corner-radius-sml;
            }
          }

        }
      }
    }
  }

  @each $color in $colors {
    $i: index($colors, $color);
    $key: nth($colorKeys,$i);
    &.chat-app--#{$key} {
      .user-item .badge-num-messages {
        background: $color;
      }
      .messages-panel {
        .message-item--me .message-item__attachment .inner,
        .message-item--me .message-item__content .inner {
          background: $color;
          border-color: $color;
        }
        .message-topbar .btn-clearChannel,
        .message-input .btn-games,
        .message-input .btn-share {
          color: $color;
          label {
            color: $color;
          }
          &:hover, &:active, &:focus {
            color: darken($color, 10%);
            label {
              color: darken($color, 10%);
            }
          }
        }
        .message-input__msg textarea:focus,
        .message-input__msg input:focus {
          border-color: lighten($color, 30%);
        }
      }

      .btn-leavechat,
      .messages-panel .message-input .btn,
      .channel-panel__add .btn-add-channel {
        background: $color;
        border-color: $color;
        &:hover, &:active, &:focus {
          background-color: darken($color, 10%);
          border-color: darken($color, 10%);
        }
      }
    }
  }

  @include respond-medium-down {
    width: 200%;

    .channel-panel,
    .messages-panel {
      width: 50%;
    }

    &--state-channels {
      transform: translate(0, 0);
    }

    &--state-chat {
      transform: translate(-50%, 0);
    }

    .messages-panel {
      .message-topbar {
        .btn-clearChannel {
          display: block;
        }
        .channel-name {
          font-size: 1.5rem;
        }
      }
    }
  }

}


.user-status {
  font-size: 0.75rem;
  padding-left: 12px;
  position: relative;
  &:before {
    content: '';
    position:absolute;
    top:4px;
    left:0;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $green;
  }
  &--offline {
    &:before {
      background-color: $mid-grey;
    }
  }
}
